import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import NextSteps from '../components/NextSteps'

const DefaultPage = ({ data: { prismicDefaultPage } }) => {
  const { data } = prismicDefaultPage
  return (
    <Layout>
      <Helmet
        title={`Charthouse | ${data.title.text}`}
        meta={[
          { name: 'description', content: data.introduction.text},
          { property: 'og:title', content: `Charthouse | ${data.title.text}` },
          { property: 'og:description', content: data.introduction.text },
          { name: 'twitter:title', content: `Chartouse | ${data.title.text}` },
          { name: 'twitter:description', content: data.introduction.text },
        ]}
      />
      <PageHeader 
        heading={data.title.text}
        introduction={data.introduction.html}
      />
      <PageContent content={data.content.html} />
      {data.next_steps && data.next_steps.length > 0 && 
        <NextSteps
          steps={data.next_steps}
        />
      }
    </Layout>
  )
}

export default DefaultPage

export const pageQuery = graphql`
  query DefaultPageBySlug($uid: String!) {
    prismicDefaultPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        introduction {
          html
          text
        }
        content {
          html
        }
        next_steps {
          heading {
            text
          }
          button_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`